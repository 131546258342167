import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import ShopNavigation from 'src/components/ShopNavigation'
import Drawer from 'src/components/Drawer'
import MobileMenu from 'src/components/MobileMenu'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import Modal from 'src/components/Modal'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import { util, colors } from 'src/styles'

const propTypes = {
	data: PropTypes.object.isRequired
}

class PageTemplate extends React.Component {
	state = {
		popupVisible: false
	}

	componentDidMount () {
		if (window && window.sessionStorage) {
			const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.internalName.includes('PLACEHOLDER'))[0].node
			const { popup } = site

			const popupDismissed = window.sessionStorage.getItem('popupDismissed')

			if (popup && !popupDismissed) {
				this.setState({
					popupVisible: true
				})
			}
		}
	}

	closePopup = event => {
		if (window && window.sessionStorage) {
			this.setState({ popupVisible: false })
			window.sessionStorage.setItem('popupDismissed', 'true')
		}
	}

	render () {
		const { popupVisible } = this.state
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.internalName.includes('PLACEHOLDER'))[0].node
		const page = this.props.data.allContentfulPage.edges[0].node
		const { sections, slug, category } = page
		const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const { seo } = page
		const { popup } = site

		return (
			<Fragment>
				<SEO
					title={page.title}
					description={seo.description && seo.description.description}
					siteSettings={site}
					keywords={seo.keywords}
					shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
				/>
				<Header
					hasAtf={hasAtf}
					location={slug}
					bannerText={site && site.bannerText}
					bannerColor={site && site.bannerColor}
					navigation={site && site.navigation}
				/>

				<Drawer />
				<ResponsiveComponent
					small={
						<MobileMenu
							navLinks={site && site.navigation}
						/>
					}
					large={<span />}
				/>

				{category === 'shop' && (
					<ShopNavigation current={slug} navigation={site && site.navigation} />
				)}
				{sections && sections.map((section, index) => {
					const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
					const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
					const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
					const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
					const lastSection = sections.length === index + 1
					return (
						<ComponentRenderer
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={`${ section.id }_${ index }`}
							item={section}
							index={index}
							location={slug}
							navigation={site && site.navigation}
						/>
					)
				})}
				<Footer {...site} footerTheme={page.footerTheme} />

				{popup && (
					<Modal
						open={popupVisible}
						onClose={this.closePopup}
						css={css`.modal-box { width: 100%; max-width: 800px; background: ${ colors.offwhite }; }`}
						padded={popup.padded}
						position={popup.position}
					>
						<div style={{ textAlign: 'center' }}>
							{popup.heading && (
								<h3 style={{ marginTop: 0 }}>{popup.heading}</h3>
							)}
							{popup.text && (
								<ContentfulRichText richText={popup.text.json} />
							)}
							{popup.content && (
								<ComponentRenderer
									prevTheme={false}
									nextTheme={false}
									isFirstSection={true}
									isLastSection={true}
									key={''}
									item={popup.content}
									index={0}
									location={slug}
									navigation={site && site.navigation}
									css={css`
										background: ${ colors.offwhite };
										${ util.responsiveStyles('padding-bottom', 40, 28, 26, 26) }
										${ util.responsiveStyles('padding-top', 30, 23, 21, 21) }
									`}
								/>
							)}
							{popup.confirmButton && (
								<Button
									{...popup.confirmButton}
									onClick={this.closePopup}
									setTheme={popup.confirmButton.theme}
									css={css`margin-bottom: 14px;`}
								>
									{popup.confirmButton.label}
								</Button>
							)}
							<br/>
							{popup.dismissButton && (
								<TextLink
									onClick={this.closePopup}
								>
									{popup.dismissButton.label}
								</TextLink>
							)}
						</div>
					</Modal>
				)}

			</Fragment>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
	query($id: String!) {
		allContentfulSiteSettings {
			edges {
				node {
					...SiteSettings
				}
			}
		}
		allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
					category
					footerTheme
					sections {
						...Columns
						...FiftyFifty
						...TextSection
						...WideMedia
						...ProductGrid
						...FeaturedCollection
						...Lookbook
						...FabricsList
						...ContactForm
					}
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
		}
		allContentfulCollection(filter: {internalName: {nin: "PLACEHOLDER Collection"}}) {
			edges {
				node {
					id
					collectionHandle
					displayTitle
				}
			}
		}
	}
`

export default PageTemplate
