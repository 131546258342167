import React from 'react'
import { rgba } from 'polished'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import { typography, colors, util } from 'src/styles'

const Wrapper = styled.div`
	position: relative;
	z-index: 2;
	${ util.responsiveStyles('padding-top', 80, 50, 44, 44) }
	margin-bottom: 6px;
	padding-bottom: 6px;
	overflow: auto;
`

const LinkItem = styled(Link)`
	${ typography.h3Alt }
	display: block;
	${ ({ active }) => active ? `
		color: ${ colors.textColor };
	` : `
		color: ${ rgba(colors.green, 0.5) };
		&:hover {
			color: ${ colors.green };
		}
	` }
`

const NavigationItems = styled.ul`
	display: flex;
	list-style: none;
	white-space: nowrap;
	padding: 0;
	margin: 0;
	li {
		padding: 0;
		&:last-of-type {
			padding-right: ${ 100 / 14 + 'vw' };
		}
		&:not(:last-of-type) {
			a {
				${ util.responsiveStyles('margin-right', 32, 26, 24, 20) }
			}
		}
	}
`

const ShopNavigation = ({ className, current, navigation }) => {
  const shopLink = navigation && navigation.find(x => (x.to === '/shop' || x.to === 'shop'))
  const { dropdownLinks } = (shopLink || {})
  return (
    <Wrapper>
      <Grid small='1 [12] 1' large='1 [24] 1'>
        <NavigationItems className={className}>
          {/* <li><LinkItem to="/shop" active={current === 'shop'}>Shop All</LinkItem></li> */}
          {dropdownLinks && dropdownLinks.map((link, index) => (
            <li>
              <LinkItem
                active={current && link && link.to && link.to.includes(current)}
                to={link.to}
              >
                {link.label}
              </LinkItem>
            </li>
          ))}
        </NavigationItems>
      </Grid>
    </Wrapper>
  )
}

export default ShopNavigation
